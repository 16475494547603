<template>
    <div class="main">
		<WorkerDetailsNavbar
		ref="workerDetailsNavbar"
		@change-page-clicked="changePageClicked()"
		:changePageDisabled="changePageDisabled"
		></WorkerDetailsNavbar>
		<router-view 
		@set-state="setState"
		@cancel-clicked="cancelClicked"
		@action-result="actionResult"
		@disable-go-back="disableGoBack($event)"
		@set-new-title="setNewTitle"
		></router-view>

		<v-dialog 
			persistent 
			v-model="goBackWithoutSavingConfirmation.visible"
			max-width="295"
		>
			<v-card>
				<v-card-title>Wait</v-card-title>
				<v-card-text style="text-align:justify"> Do you want close this window? Any provided data will not be saved.</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="grey"
						text
						@click="() => { goBackWithoutSavingConfirmation.visible = false; }">
						No
					</v-btn>

					<v-btn color="red darken-1"
						text
						@click="closeDialogConfirmCallback()">
						Yes
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-snackbar
             v-model="snackbar.visible"
            :color="snackbar.color"
        >
            {{ snackbar.message }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar.visible = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
		</v-snackbar>
    </div>  
</template>

<script>
//import WorkerDetailsNavbar from './WorkerDetailsNavbar.vue'
const WorkerDetailsNavbar = () => import('./WorkerDetailsNavbar.vue');

export default {
	name: 'WorkerDetails',

	components: {
		WorkerDetailsNavbar,
	},

	props: {
		workerFullName: String,
		workerId: Number,
		goBackClicked: Boolean
	},

	data: function() {
		return {
			mutableWorkerFullName: this.workerFullName,
			mutableWorkerId: this.workerId,
			selectedItem: {
                permission: null,
                name: "Cards",
                path: "cards"
            },

            modules: [
                {
                    permission: null,
                    name: "Personal data",
                    path: ""
                },
                {
                    permission: null,
                    name: "Projects",
                    path: ""
                },
                {
                    permission: null,
                    name: "Cards",
                    componentName: "cards"
                },
            ],
			snackbar: {
				visible: false,
				color: "primary",
				message: ""
			},
			goBackWithoutSavingConfirmation: {
				visible: false,
				type: null, //back - kiedy została kliknęta strzałka powrotu lub 'Cancel'; change - kiedy zmieniamy zakładkę
			},
			changePageDisabled: false,
		}
	},
	mounted(){
		if(!localStorage.getItem('tenantSlug')){
			this.$router.push('/choose-tenant');
			return
		}
		if(this.mutableWorkerFullName && this.mutableWorkerId){
			localStorage.cardsWorkerFullName=JSON.stringify(this.mutableWorkerFullName)
			localStorage.cardsWorkerId=JSON.stringify(this.mutableWorkerId)
		}
		else{
			this.mutableWorkerFullName = JSON.parse(localStorage.cardsWorkerFullName)
			this.mutableWorkerId = JSON.parse(localStorage.cardsWorkerId)
		}
		this.setTitle();
		this.getGoBackLink();
	},

	methods: {
		setTitle() {
			this.$emit('set-title', this.mutableWorkerFullName);
		},
		setNewTitle(title){
			this.mutableWorkerFullName=title
			this.setTitle()
		},
		getGoBackLink() {
			this.$emit('getGoBackLink', "/workers");
		},
		setState(newState, additionalParams = null){
			this.$emit('set-state', newState, additionalParams);
		},
		actionResult(result, message){
			this.snackbar.visible=true
			this.snackbar.color=result
			this.snackbar.message=message
		},
		disableGoBack(value){
			this.$emit('disable-go-back', value)
			this.changePageDisabled=value
		},
		changePageClicked(){
			this.goBackWithoutSavingConfirmation.type = "change"
			this.goBackWithoutSavingConfirmation.visible=true
		},
		cancelClicked(){
			this.goBackWithoutSavingConfirmation.type = "back"
			this.goBackWithoutSavingConfirmation.visible=true
		},
		closeDialogConfirmCallback: function() {
			this.$emit('disable-go-back', false)
			this.changePageDisabled=false
			this.goBackWithoutSavingConfirmation.visible=false
			if(this.goBackWithoutSavingConfirmation.type=='back'){
				this.$router.push('/workers');
			}
			else if(this.goBackWithoutSavingConfirmation.type=='change'){
				this.$refs.workerDetailsNavbar.confirmChangePageCallback()
			}
			this.goBackWithoutSavingConfirmation.type=null
		},
    },
	watch:{
		goBackClicked: function(){
			this.goBackWithoutSavingConfirmation.type = "back"
			this.goBackWithoutSavingConfirmation.visible=true
			this.$emit('go-back-clicked-taken');
		},
	}
};
</script>

<style scoped>

.v-navigation-drawer {
	max-width: 80%;
}


	.appBarInfo {
		margin-left: 250px;
		display: flex;
		align-items: center;
	}

@media only screen and (max-width: 960px) { 
	.appBarInfo {
		margin-left: 0;
		display: flex;
		align-items: center;
	}
}

</style>
